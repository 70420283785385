import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class DataService {
  constructor(private http: HttpClient) { }
  // v2 API's
  refresh: any;
  tableData = new BehaviorSubject<any>([]);
  updateFalseCount = new BehaviorSubject<any>({});
  getBulkData= new BehaviorSubject<string>('');
  checkPreviosPath = new BehaviorSubject<boolean>(false);
  bDisplayTenantSwitch = new BehaviorSubject<boolean>(false);
  switchTenantTrigger = new BehaviorSubject<boolean>(false);
  reloadHeader = new BehaviorSubject<boolean>(false);
  callConversationHistory = new BehaviorSubject<boolean>(false);
  private falseCountSubject = new BehaviorSubject<number>(0);
  falseCount$ = this.falseCountSubject.asObservable();
  DeleteUsers(arg0: { folderId: any }) {
    throw new Error("Method not implemented.");
  }
  showQnAtable = new BehaviorSubject<boolean>(false);
  goNext = new BehaviorSubject<boolean>(false);
  goYandar = new BehaviorSubject<boolean>(false);

  GetBuildNumber(body): Observable<any> {
    return this.http.post<any>(environment.BaseURL + "/_systemInfo", body);
  }

  // User Manager Api

  GetUsersList(body): Observable<any> {
    return this.http.post<any>(
      environment.BaseURL + "/ds/page/auth/vault",
      body
    );
  }

  CreateUser(body): Observable<any> {
    return this.http.post<any>(
      environment.BaseURL + "/_service/um/newUser",
      body
    );
  }

  UploadUsers(body): Observable<any> {
    return this.http.post<any>(
      environment.BaseURL + "/authds/uploadUsers",
      body
    );
  }

  EditUser(body): Observable<any> {
    return this.http.post<any>(
      environment.BaseURL + "/_service/um/editUser",
      body
    );
  }

  SaveEditUserProfile(body): Observable<any> {
    return this.http.post<any>(
      environment.BaseURL + "/ds/function/shared/SaveEditUserProfileV5",
      body
    );
  }

  ResetPassword(body): Observable<any> {
    return this.http.post<any>(
      environment.BaseURL + "/_service/um/resetPassword",
      body
    );
  }

  // Reset password
  ResetLoginPassword(body): Observable<any> {
    return this.http.post<any>(
      environment.BaseURL + "/_preauthsvc/user/resetPassword",
      body
    );
  }

  SuspendUser(body): Observable<any> {
    return this.http.post<any>(
      environment.BaseURL + "/_service/um/toggleSuspendActive",
      body
    );
  }

  DeleteUser(id: any): Observable<any> {
    return this.http.delete<any>(
      environment.BaseURL + "/_service/um/deleteUser" + "?id=" + id
    );
  }

  // ****** query
  GoogleTranscriptionTextTrigger(body): Observable<any> {
    return this.http.post(
      environment.BaseURL +
      "/ds/function/shared/GoogleTranscriptionTextTrigger",
      body
    );
  }

  GoogleTranscriptionChecker(body): Observable<any> {
    return this.http.post(
      environment.BaseURL + "/ds/function/shared/GoogleTranscriptionChecker",
      body
    );
  }

  GetHelpCenterQuerrySuggestions(body): Observable<any> {
    return this.http.post<any>(
      environment.BaseURL + "/ds/function/shared/ODMHelpCenterAutoComplete",
      body
    );
  }

  //Upload Image
  UploadImage(body): Observable<any> {
    return this.http.post<any>(
      environment.BaseURL + "/foldersvc/cloudstorage/upload",
      body
    );
  }


  AddNewTenant(body): Observable<any> {
    return this.http.post<any>(
      environment.BaseURL + "/ds/save/auth/vault/Org",
      body
    );
  }

  // ************ Sales Region

  SalesRegionGetRecords(body): Observable<any> {
    return this.http.post<any>(
      environment.BaseURL + "/ds/function/shared/GetTableDataV5",
      body
    );
  }

  SalesRegionSaveRecords(body): Observable<any> {
    return this.http.post<any>(
      environment.BaseURL + "/ds/function/shared/SaveEditDataV5",
      body
    );
  }

  DeleteRecords(body): Observable<any> {
    return this.http.post<any>(
      environment.BaseURL + "/ds/function/shared/DeleteRecordsV5",
      body
    );
  }

  SalesRegionFilterRecords(body): Observable<any> {
    return this.http.post<any>(
      environment.BaseURL + "/ds/function/shared/FilterRecord",
      body
    );
  }

  // ************ Schedular-details
  ShareEvent(body): Observable<any> {
    return this.http.post(
      environment.BaseURL + "/ds/function/shared/SharePromotionalEventV5",
      body
    );
  }

  DeleteDateEvent(body): Observable<any> {
    return this.http.post<any>(
      environment.BaseURL + "/ds/function/shared/DeletePromotionV5",
      body
    );
  }

  SaveEventsData(body): Observable<any> {
    return this.http.post<any>(
      environment.BaseURL + "/ds/function/shared/SavePromotionDataV5",
      body
    );
  }

  GetPromotionalCalenderData(body): Observable<any> {
    return this.http.post<any>(
      environment.BaseURL + "/ds/function/shared/V3GetPromoCalendar",
      body
    );
  }

  DeletePromotionImage(body): Observable<any> {
    return this.http.post<any>(
      environment.BaseURL + "/ds/function/shared/DeletePromotionalImageV5",
      body
    );
  }

  // ************ Get Info Report
  SearchAvailableItems(body): Observable<any> {
    return this.http.post<any>(
      environment.BaseURL + "/ds/function/shared/SearchAvailableItemsV5",
      body
    );
  }

  // *********** Upload 3D model
  upload3DModel(body): Observable<any> {
    return this.http.post<any>(
      environment.BaseURL + "/ds/function/shared/Save3DModelsV5",
      body
    );
  }

  // *********** Email share
  shareSelectedItems(body): Observable<any> {
    return this.http.post<any>(
      environment.BaseURL + "/ds/function/shared/ShareSelectedItemsV5",
      body
    );
  }

  // ************ Get Store Location BrandsNames

  GetStoresLocationBrandsNames(body): Observable<any> {
    return this.http.post<any>(
      environment.BaseURL + "/ds/function/shared/GetBrandNamesV5",
      body
    );
  }
  // ************ Get Store Location CustomerNames

  GetStoresLocationCustomerNames(body): Observable<any> {
    return this.http.post<any>(
      environment.BaseURL + "/ds/function/shared/GetCustomerNamesV5",
      body
    );
  }

  // ************ Get  ItemsInformation

  GetItemInformation(body): Observable<any> {
    return this.http.post<any>(
      environment.BaseURL + "/ds/function/shared/GetItemNamesV5",
      body
    );
  }

  // ************ Get  ItemsInformation

  GetSalesRepresentativeInformation(body): Observable<any> {
    return this.http.post<any>(
      environment.BaseURL + "/ds/function/shared/GetSalesRepV5",
      body
    );
  }

  // ************ Get Store Location MAP V2

  GetStoresLocationMap(body): Observable<any> {
    return this.http.post<any>(
      environment.BaseURL + "/ds/function/shared/GetStoreLocationV5",
      body
    );
  }

  // ************ Upload Available Item Image

  uploadAvailableItemImage(body): Observable<any> {
    return this.http.post<any>(
      environment.BaseURL + "/ds/function/shared/AddItemImageV5",
      body
    );
  }

  // ************ Get Notifications

  GetNotifications(body): Observable<any> {
    return this.http.post<any>(
      environment.BaseURL + "/ds/function/shared/ODMGetNotifications",
      body
    );
  }

  // ************ Get Notifications Count

  GetNotificationsCount(body): Observable<any> {
    return this.http.post<any>(
      environment.BaseURL + "/ds/function/shared/ODMNotificationsCount",
      body
    );
  }

  // ************ Get Notifications View

  GetNotificationViewUpdate(body): Observable<any> {
    return this.http.post<any>(
      environment.BaseURL + "/ds/function/shared/ODMNotificationViewUpdate",
      body
    );
  }

  // ************ Get Notifications Count

  getV2AddStoreImage(body): Observable<any> {
    return this.http.post<any>(
      environment.BaseURL + "/ds/function/shared/AddStoreImageV5",
      body
    );
  }

  DownloadStoreItems(body): Observable<any> {
    return this.http.post<any>(
      environment.BaseURL + "/ds/function/shared/DownloadStoreItemsV5",
      body
    );
  }

  // ************  ---> Filter by ChainName of record

  filterReportsData(body): Observable<any> {
    return this.http.post<any>(
      environment.BaseURL + "/ds/function/shared/GetFilterDataV5",
      body
    );
  }

  //----------    v3 APIs ------------
  // ************  Dashboard ---> Business Summary API
  GetBusinessData(body): Observable<any> {
    return this.http.post<any>(
      environment.BaseURL + "/ds/function/shared/GetBusinessDataV5",
      body
    );
  }

  // ************  Dashboard ---> TOP Performers API
  GetTopItems(body): Observable<any> {
    return this.http.post<any>(
      environment.BaseURL + "/ds/function/shared/GetTopItemsV5",
      body
    );
  }

  // ************  Dashboard ---> Promotional Calendar
  GetEventsData(body): Observable<any> {
    return this.http.post<any>(
      environment.BaseURL + "/ds/function/shared/GetPromotionDataV5",
      body
    );
  }

  // ************  Dashboard ---> Daily Diamond

  GetDailyDiamonds(body): Observable<any> {
    return this.http.post<any>(
      environment.BaseURL + "/ds/function/shared/GetDailyDiamondsV5",
      body
    );
  }

  deleteDailyDiamonds(body): Observable<any> {
    return this.http.post<any>(
      environment.BaseURL + "/ds/function/shared/DeleteDailyDiamondV5",
      body
    );
  }

  // Forget password
  ForgetPassword(body): Observable<any> {
    return this.http.post<any>(
      environment.BaseURL + "/_preauthsvc/user/forgotPassword",
      body
    );
  }

  GetReportsData(body): Observable<any> {
    return this.http.post<any>(
      environment.BaseURL + "/ds/function/shared/GetVendorReportV5",
      body
    );
  }

  GetRetailerReportsData(body): Observable<any> {
    return this.http.post<any>(
      environment.BaseURL + "/ds/function/shared/GetRetailerReportV5",
      body
    );
  }

  // ************  Survey ---> GetStoreSurveyData

  GetStoreSurveyData(body): Observable<any> {
    return this.http.post(
      environment.BaseURL + "/ds/function/shared/V2GetStoreSurveyData",
      body
    );
  }

  SaveStoreSurveyData(body): Observable<any> {
    return this.http.post(
      environment.BaseURL + "/ds/function/shared/V3SaveStoreSurveyData",
      body
    );
  }

  ChangePassword(body): Observable<any> {
    return this.http.post<any>(
      environment.BaseURL + "/_service/um/changePassword",
      body
    );
  }

  GetDDData(body): Observable<any> {
    return this.http.post<any>(
      environment.BaseURL + "/ds/function/shared/GetDDDataV5",
      body
    );
  }

  SaveDailyDiamond(body): Observable<any> {
    return this.http.post<any>(
      environment.BaseURL + "/ds/function/shared/SaveDailyDiamondV5",
      body
    );
  }

  SaveDailyDiamondLikes(body): Observable<any> {
    return this.http.post<any>(
      environment.BaseURL + "/ds/function/shared/SaveLikesV5",
      body
    );
  }

  updateChainName(body): Observable<any> {
    return this.http.post<any>(
      environment.BaseURL + "/ds/function/shared/UpdateChainInformationV5",
      body
    );
  }

  BulkUploadCSVFiles(body): Observable<any> {
    return this.http.post<any>(
      environment.BaseURL + "/ds/function/shared/zzProcessCsvFilesTrigger",
      body
    );
  }
  dailyDiamondCSVHandler(body): Observable<any> {
    return this.http.post<any>(
      environment.BaseURL + "/ds/function/shared/DailyDiamondCsvHandlerV5",
      body
    );
  }

  FilterRecords(body): Observable<any> {
    return this.http.post<any>(
      environment.BaseURL + "/ds/function/shared/FilterRecordsV5",
      body
    );
  }

  GetFlyersImage(body): Observable<any> {
    return this.http.post<any>(
      environment.BaseURL + "/ds/function/shared/GetBrandFlyersImageV5",
      body
    );
  }

  DeleteItemImages(body): Observable<any> {
    return this.http.post<any>(
      environment.BaseURL + "/ds/function/shared/DeleteItemImageV5",
      body
    );
  }

  DeleteBrandLogo(body): Observable<any> {
    return this.http.post<any>(
      environment.BaseURL + "/ds/function/shared/DeleteBrandLogoV5",
      body
    );
  }

  GetCategoryFlyersImage(body): Observable<any> {
    return this.http.post<any>(
      environment.BaseURL + "/ds/function/shared/GetCategoryFlyersV5",
      body
    );
  }

  GetWeeklyFlyersImage(body): Observable<any> {
    return this.http.post<any>(
      environment.BaseURL + "/ds/function/shared/GetPromotionFlyersV5",
      body
    );
  }

  GetPreviouslyFlyersImage(body): Observable<any> {
    return this.http.post<any>(
      environment.BaseURL + "/ds/function/shared/GetPreviousPromotionFlyersV5",
      body
    );
  }

  //Upload Image
  UploadImageApi(body): Observable<any> {
    return this.http.post<any>(
      environment.BaseURL + "/ds/function/shared/AvailableItemV5",
      body
    );
  }

  shareFlyersItems(body): Observable<any> {
    return this.http.post<any>(
      environment.BaseURL + "/ds/function/shared/ShareFlyersV5",
      body
    );
  }

  DeleteStoreLocationImages(body): Observable<any> {
    return this.http.post<any>(
      environment.BaseURL + "/ds/function/shared/DeleteStoreImagesV5",
      body
    );
  }

  //BlingBot API's

  GetSuggestions(body): Observable<any> {
    return this.http.post<any>(
      environment.BaseURL + "/ds/function/shared/GetSuggestionsV5",
      body
    );
  }

  GetResponse(body): Observable<any> {
    return this.http.post<any>(
      environment.BaseURL + "/ds/function/shared/GetResponseV5",
      body
    );
  }
  GetAiResponse(body): Observable<any> {
    return this.http.post<any>(
      environment.BaseURL + "/ds/function/shared/zzGetAiResponse",
      body
    );
  }


  GetConversationHistory(body): Observable<any> {
    return this.http.post<any>(
      environment.BaseURL + "/ds/function/shared/GetDocAiHistoryV5",
      body
    );
  }

  GetConversationDetails(body): Observable<any> {
    return this.http.post<any>(
      environment.BaseURL + "/ds/function/shared/GetDocAiChatDetailsV5",
      body
    );
  }

  DeleteConversation(body): Observable<any> {
    return this.http.post<any>(
      environment.BaseURL + "/ds/function/shared/DeleteDocAiConversationsV5",
      body
    );
  }

  DeleteAllConversations(body): Observable<any> {
    return this.http.post<any>(
      environment.BaseURL + "/ds/function/shared/DeleteAllConversationsV5",
      body
    );
  }

  SendingUploadFileToAIModel(body): Observable<any> {
    return this.http.post<any>(
      environment.BaseURL + "/ds/function/shared/UploadFileV5",
      body
    );
  }

  SendFeedback(body): Observable<any> {
    return this.http.post<any>(
      environment.BaseURL + "/ds/function/shared/SendFeedbackV5",
      body
    );
  }

  TaskStatusHandler(body): Observable<any> {
    return this.http.post<any>(
      environment.BaseURL + "/ds/function/shared/TaskStatusHandlerV5",
      body
    );
  }

  SpeechToText(body): Observable<any> {
    return this.http.post(
      environment.BaseURL + "/ds/function/shared/SpeechToTextV5",
      body
    );
  }

  //Email sharing
  emailSharingReports(body): Observable<any> {
    return this.http.post<any>(
      environment.BaseURL + "/ds/function/shared/zzShareReports/postMethodChangeMe",
      body
    );
  }

  downloadCsvReport(body): Observable<any> {
    return this.http.post<any>(
      environment.BaseURL + "/ds/function/shared/zzDownloadReports",
      body
    );
  }

  DownloadDDColumns(body): Observable<any> {
    return this.http.post<any>(
      environment.BaseURL + "/ds/function/shared/DownloadDDColumnsV5",
      body
    );
  }

  getTenantInfoRecords(body): Observable<any> {
    return this.http.post<any>(
      environment.BaseURL + "/ds/function/shared/GetTenantsInfoV5",
      body
    );
  }

  addCategoryFlyers(body): Observable<any> {
    return this.http.post<any>(
      environment.BaseURL + "/ds/function/shared/AddCategoryFlyersV5",
      body
    );
  }

  deleteCategoryFlyers(body): Observable<any> {
    return this.http.post<any>(
      environment.BaseURL + "/ds/function/shared/zzDeleteCategoryFlyers",
      body
    );
  }

  getItemInfo(body): Observable<any> {
    return this.http.post<any>(
      environment.BaseURL + "/ds/function/shared/GetTenantItemInfoV5",
      body
    );
  }

  getItemImages(body): Observable<any> {
    return this.http.post<any>(
      environment.BaseURL + "/ds/function/shared/GetTenantItemImagesV5",
      body
    );
  }

  addWeeklyFlyers(body): Observable<any> {
    return this.http.post<any>(
      environment.BaseURL + "/ds/function/shared/AddPromotionFlyersV5",
      body
    );
  }

  WeeklyFlyersList(body): Observable<any> {
    return this.http.post<any>(
      environment.BaseURL + "/ds/function/shared/GetPromotionDatesV5",
      body
    );
  }

  deleteWeeklyFlyers(body): Observable<any> {
    return this.http.post<any>(
      environment.BaseURL + "/ds/function/shared/DeletePromotionFlyersV5",
      body
    );
  }

  addBulkBrandLogo(body): Observable<any> {
    return this.http.post<any>(
      environment.BaseURL + "/ds/function/shared/AddBulkBrandLogoV5",
      body
    );
  }

  addBrandFlyerImage(body): Observable<any> {
    return this.http.post<any>(
      environment.BaseURL + "/ds/function/shared/AddBrandFlyersV5",
      body
    );
  }

  addBrandLogo(body): Observable<any> {
    return this.http.post<any>(
      environment.BaseURL + "/ds/function/shared/AddBrandLogoV5",
      body
    );
  }

  deleteBrandFlyers(body): Observable<any> {
    return this.http.post<any>(
      environment.BaseURL + "/ds/function/shared/DeleteBrandFlyersV5",
      body
    );
  }

  GetMonthYear(body): Observable<any> {
    return this.http.post<any>(
      environment.BaseURL + "/ds/function/shared/GetMonthYearV5",
      body
    );
  }

  DeleteDDImage(body): Observable<any> {
    return this.http.post<any>(
      environment.BaseURL + "/ds/function/shared/DeleteDDImagesV5",
      body
    );
  }
  // GetUserDetail
  GetUserDetail(body): Observable<any> {
    return this.http.post<any>(
      environment.BaseURL + "/ds/function/shared/GetUserDetailV5",
      body
    );
  }

  // download file
  DownloadPdfFile(body): Observable<any> {
    return this.http.post<any>(
      environment.BaseURL + "/ds/function/shared/DownloadPdfFileV5",
      body
    );
  }

  // download file
  DeleteUserPdfFile(body): Observable<any> {
    return this.http.post<any>(
      environment.BaseURL + "/ds/function/shared/DeleteDocAiPdfV5",
      body
    );
  }

  UploadUserPDFFile(body): Observable<any> {
    return this.http.post<any>(
      environment.BaseURL + "/ds/function/shared/UploadUserPDFFileV5",
      body
    );
  }

  GetUserPdfFiles(body): Observable<any> {
    return this.http.post<any>(
      environment.BaseURL + "/ds/function/shared/GetUserPdfFilesV5",
      body
    );
  }

  GetStoresTableResponse(body): Observable<any> {
    return this.http.post<any>(
      environment.BaseURL + "/ds/function/shared/CheckStoresDataV5",
      body
    );
  }

  GetPromotionsYearsMonths(body): Observable<any> {
    return this.http.post<any>(
      environment.BaseURL + "/ds/function/shared/GetPromotionsMonthYearV5",
      body
    );
  }

  GetSalesRegionInformation(body): Observable<any> {
    return this.http.post<any>(
      environment.BaseURL + "/ds/function/shared/GetSalesRegionDescV5",
      body
    );
  }

  GetVendorsName(body): Observable<any> {
    return this.http.post<any>(
      environment.BaseURL + "/ds/function/shared/GetVendorNameV5",
      body
    );
  }

  ShareEmail(body): Observable<any> {
    return this.http.post<any>(
      environment.BaseURL + "/ds/function/shared/ShareEmailV5",
      body
    );
  }

  UpdateComment(body): Observable<any> {
    return this.http.post<any>(
      environment.BaseURL + "/ds/function/shared/UpdateCommentV5",
      body
    );
  }

  DailyDiamondComments(body): Observable<any> {
    return this.http.post<any>(
      environment.BaseURL + "/ds/function/shared/DailyDiamondCommentV5",
      body
    );
  }
  zzDeleteBulkDailyDiamond(body): Observable<any> {
    return this.http.post<any>(
      environment.BaseURL + "/ds/function/shared/DeleteBulkDailyDiamondV5",
      body
    );
  }

  ShareDailyDiamond(body): Observable<any> {
    return this.http.post<any>(
      environment.BaseURL + "/ds/function/shared/ShareDailyDiamondV5",
      body
    );
  }
  zzBulkEdit(body): Observable<any> {
    return this.http.post<any>(
      environment.BaseURL + "/ds/function/shared/BulkEditV5",
      body
    );
  }
  zzBulkDelete(body): Observable<any> {
    return this.http.post<any>(
      environment.BaseURL + "/ds/function/shared/BulkDeleteV5",
      body
    );
  }
  zzDownloadTopItems(body): Observable<any> {
    return this.http.post<any>(
      environment.BaseURL + "/ds/function/shared/DownloadTopItemsV5",
      body
    );
  }
  zzAutoCompleter(body): Observable<any> {
    return this.http.post<any>(
      environment.BaseURL + "/ds/function/shared/AutoCompleterV5",
      body
    );
  }
  //USDA lOOKUP
  
  zzGetUsdaData(body): Observable<any> {
    return this.http.post<any>(
      environment.BaseURL + "/ds/function/shared/GetUsdaDataV5",
      body
    );
  }
  zzSaveEditUsda(body): Observable<any> {
    return this.http.post<any>(
      environment.BaseURL + "/ds/function/shared/SaveEditUsdaV5",
      body
    );
  }
  zzGetEstData(body): Observable<any> {
    return this.http.post<any>(
      environment.BaseURL + "/ds/preauth_function/shared/GetEstDataV5",
      body
    );
  }

  zzGetUsdaEstRecord(body): Observable<any> {
    return this.http.post<any>(
      environment.BaseURL + "/ds/preauth_function/shared/GetUsdaEstRecordV5",
      body
    );
  }
  zzDownloadReportsSummary(body): Observable<any> {
    return this.http.post<any>(
      environment.BaseURL + "/ds/function/shared/DownloadReportsSummaryV5",
      body
    );
  }

  zzCreateFolderWhileUploadingFilesForBlingBot(body): Observable<any> {
    return this.http.post<any>(
      environment.BaseURL + "/docsvc/cloudstorage/folder/create",
      body
    );
  }
  zzGetFolders(): Observable<any> {
    return this.http.get<any>(
      environment.BaseURL + "/api/v1.1/docsvc/folderTree",
    );
  }

  zzExecuteWorkflowForFolder(body): Observable<any> {
    return this.http.post<any>(
      environment.BaseURL + '/docsvc/executeWorkflow', 
      body
    );
  }
  CheckTaskStatus(body): Observable<any> {
    return this.http.get<any>(
      environment.BaseURL + `/api/v1.1/docsvc/doc/status/${body.id}`
    );
  }

  TrilloDocAISearch(body): Observable<any>
  {
    return this.http.post<any>(
      environment.BaseURL + '/ds/function/shared/GetDocAiResponseV5',
      body
    );
  }

  TrilloGetDocs(body): Observable<any>
  {
    return this.http.post<any>(
      environment.BaseURL + '/docsvc/foldersAndPaginatedDocs',
      body
    );
  }

  
  UploadDocAICreateConversation(body): Observable<any>
  {
    return this.http.post<any>(
      environment.BaseURL + '/ds/function/shared/zzUploadDocAI',
      body
    );
  }

}

