
// // Registering Syncfusion license key
// import { registerLicense } from '@syncfusion/ej2-base';
// registerLicense('ORg4AJUWIQA/GntZVinhQIJCIV5AQmBIYVp/TGpjff96cVxMZVVBJAtUQF1hSn95dk1iXX1ecnVWQWhU=');

import { registerLicense } from '@syncfusion/ej2-base';
// registerLicense('ORg4AjUWIQA/Gnt2VlhhQlJCfV5AQmBIYVp/TGpJfl96cVxMZVVBJAtUQF1hSn9Sd0NjUH5XdHRTQ2BV=');
registerLicense('Ngo9BigBOggjHTQxAR8/V1NAaF5cWWJCf1FpRmJGdld5fUVHYVZUTXxaS00DNHVRdkdnWX5dcHRdQ2BZUUR1XkA=');



export const environment = {
  production: window["env"]["production"] || false,
  APP_NAME: window["env"]["APP_NAME"] || 'OneDiamondMarketing',
  Title: window["env"]["Title"] || 'Turbo UPC ',
  BaseURL: window["env"]["BaseURL"] || 'https://app.onediamond-staging.trilloapps.com',
  AccessToken: window["env"]["AccessToken"] || 'lsOneDiamondAccessToken',
  branch: window["env"]["branch"] || 'development/2.0',
};